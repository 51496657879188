import * as consts from "./consts";
import axios from "axios";
import {
  CartDetails,
  CauseCollection,
  CodeDetails,
  DefaultCodeDetails,
  Charity,
  Settings,
  DefaultSettings,
  Donation,
  Query,
  SearchResult,
  Testimonial,
} from "../types";

const createHTTPHeaders = async (getAccessTokenSilently: any) => {
  if (getAccessTokenSilently) {
    let token = await getAccessTokenSilently();
    let options = token
      ? { headers: { Authorization: `Bearer ${token}` } }
      : {};
    console.log(`Bearer ${token}`);
    return options;
  } else {
    return {};
  }
};

export const getSettings = async (themeId: string): Promise<Settings> => {
  if (themeId) {
    try {
      let response = await axios.get(
        `${
          consts.BLOB_URL
        }/v4-themes/${themeId.toLowerCase()}/settings.json?timestamp=${new Date().getTime()}`,
        {}
      );
      return { ...DefaultSettings, ...response.data };
    } catch (error) {
      return { ...DefaultSettings };
    }
  } else {
    return { ...DefaultSettings };
  }
};

export const checkManagerRole = async (
  ein: string,
  getAccessTokenSilently: any
): Promise<boolean> => {
  let options = await createHTTPHeaders(getAccessTokenSilently);
  let response = await axios.get(
    `${consts.SERVICE_URL}/charities/${ein}/ismanager`,
    options
  );
  return response.data;
};

export const getCauseCollection = async (
  causeCollectionId: string
): Promise<CauseCollection> => {
  let response = await axios.get(
    `${
      consts.BLOB_URL
    }/v4-causes/${causeCollectionId}.json?timestamp=${new Date().getTime()}`,
    {}
  );
  return response.data;
};

export const getCharity = async (ein: string): Promise<Charity> => {
  let response = await axios.get(
    `${consts.BLOB_URL}/v4-charities/${ein}.json`,
    {}
  );
  return response.data;
};

export const getSearchResults = async (
  query: Query
): Promise<SearchResult[]> => {
  let response = await axios.get(
    `${consts.SERVICE_URL}/charities/search?text=${query.text}${
      query.range > 0
        ? `&range=${query.range}&latitude=${query.latitude}&longitude=${query.longitude}`
        : ""
    }&skip=${query.skip}&size=${query.size}`,
    {}
  );
  return response.data.charities;
};

export const validateCode = async (
  codeDetails: CodeDetails
): Promise<CodeDetails> => {
  let response = await axios.get(
    `${consts.SERVICE_URL}/codes/${codeDetails.code}/status/${codeDetails.email}/${codeDetails.name}`,
    {}
  );
  if (response.data.valid) {
    localStorage.setItem(
      consts.STORAGE_CODE_DETAILS,
      JSON.stringify(response.data)
    );
  } else {
    localStorage.removeItem(consts.STORAGE_CODE_DETAILS);
  }
  return response.data;
};

export const fetchCodeDetails = (
  currentThemeId: string,
  setCodeDetails: (codeDetails: CodeDetails) => void
): string => {
  let themeId = currentThemeId;
  let storageCodeDetails = localStorage.getItem(consts.STORAGE_CODE_DETAILS);
  if (storageCodeDetails) {
    let currentCodeDetails: CodeDetails = JSON.parse(storageCodeDetails);
    themeId = currentCodeDetails.themeId;
    setCodeDetails(currentCodeDetails);
  }
  return themeId;
};

export const fetchCartDetails = (
  setCartItemCount: (cartItemCount: number) => void
): number => {
  let cartItemCount = 0;
  let storageCartDetails = localStorage.getItem(consts.STORAGE_CODE_DETAILS);
  if (storageCartDetails) {
    let currentCartDetails: CartDetails = JSON.parse(storageCartDetails);
    currentCartDetails.map((item) => {
      cartItemCount += item.cardDetails.length;
    });
    setCartItemCount(cartItemCount);
  }
  return cartItemCount;
};

export const fetchDonations = (
  setDonations: (donations: Donation[]) => void
) => {
  let storageDonations = localStorage.getItem(consts.STORAGE_DONATIONS);
  let currentDonations: Donation[] = storageDonations
    ? JSON.parse(storageDonations)
    : [];
  setDonations(currentDonations);
};

export const updateDonations = (donations: Donation[]) => {
  localStorage.setItem(consts.STORAGE_DONATIONS, JSON.stringify(donations));
};

export const saveDonations = async (
  code: string,
  name: string,
  email: string,
  shareSelections: boolean,
  message: string,
  donations: Donation[],
  allowTracking: boolean,
  trackingEmail: string
): Promise<boolean> => {
  let response = await axios.post(`${consts.SERVICE_URL}/donations`, {
    code: code,
    name: name,
    email: email,
    shareSelections: shareSelections,
    message: message,
    donations: donations,
    allowTracking: allowTracking,
    trackingEmail: trackingEmail,
  });

  let storageCodeDetails = localStorage.getItem(consts.STORAGE_CODE_DETAILS);
  if (storageCodeDetails) {
    let currentCodeDetails: CodeDetails = JSON.parse(storageCodeDetails);
    currentCodeDetails.spendCompleted = true;
    localStorage.setItem(
      consts.STORAGE_CODE_DETAILS,
      JSON.stringify(currentCodeDetails)
    );
  }

  return response.data;
};

export const clearCookies = async (): Promise<boolean> => {
  localStorage.removeItem(consts.STORAGE_CODE_DETAILS);
  localStorage.removeItem(consts.STORAGE_DONATIONS);
  localStorage.removeItem(consts.STORAGE_CHECKOUT);
  return true;
};

export const getCardImage = async (code: string): Promise<Blob> => {
  let response = await axios.get(
    `${consts.SERVICE_URL}/images/codes/${code}?format=overlay-default-horizontal-with-text&size=small`,
    { responseType: "blob" }
  );
  return response.data;
};

export const turnOnDirectDonation = () => {
  let directDonationCodeDetails: CodeDetails = {
    ...DefaultCodeDetails,
    valid: true,
    code: consts.DIRECT_CODE,
  };
  localStorage.setItem(
    consts.STORAGE_CODE_DETAILS,
    JSON.stringify(directDonationCodeDetails)
  );
};

export const getCNData = async (ein: string): Promise<any> => {
  let response = await axios.post(
    `https://data.charitynavigator.org`,
    {
      query: `
        query GetPublicSearchFaceted($term: String!) {
          publicSearchFaceted(term: $term) {
            results {
              highest_level_advisory
              cause
              encompass_publication_date
              encompass_score
              encompass_star_rating
              mission
              organization_url
              name
            }
          }
        }
      `,
      variables: {
        term: ein,
      },
    },
    {
      headers: {
        "Stellate-Api-Token": consts.CN_API_KEY,
      },
    }
  );
  return response.data;
};

export const getTestimonials = async (ein: string): Promise<Testimonial[]> => {
  let response = await axios.get(
    `${consts.SERVICE_URL}/testimonials/${ein}`,
    {}
  );
  return response.data;
};
