export const SERVICE_URL: string = process.env.SERVICE_URL;
export const SITE_URL = process.env.SITE_URL;
export const BLOB_URL: string = process.env.BLOB_URL;
export const CN_API_KEY: string = process.env.CN_API_KEY;
export const AUTH0_DOMAIN: string = process.env.AUTH0_DOMAIN;
export const AUTH0_AUDIENCE: string = process.env.AUTH0_AUDIENCE;
export const AUTH0_CLIENTID: string = process.env.AUTH0_CLIENTID;
export const AUTH0_SCOPE: string =
  "openid profile manage:images manage:addresses manage:orders";

export const ALERT_TYPE_ERROR = "Error";
export const ALERT_TYPE_SUCCESS = "Success";

export const STORAGE_CODE_DETAILS = "STORAGE_CODE_DETAILS";
export const STORAGE_DIRECT_DONATION = "STORAGE_DIRECT_DONATION";
export const STORAGE_DONATIONS = "STORAGE_DONATIONS";
export const STORAGE_CART = "TB_CART";
export const STORAGE_CHECKOUT = "STORAGE_CHECKOUT";

export const DIRECT_CODE = "000-000";
